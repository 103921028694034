body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.connection-container {
  display: flex;
  justify-content: center;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.main-container {
  width: 250px;
  padding: 15px;
  padding-right: 15px;
}

.button {
  margin-top: 10px;
  margin-right: 10px;
}

.logout {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  color: white;
}

.header {
  background-color: #28384b;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center
}

.body {
  padding: 15px
}

.app-title {
  text-align: center
}